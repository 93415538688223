import React from "react";
import { CardDeal, Footer } from "../components";
import styles from "../style";

export default function Contact() {
  return (
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div>
        <CardDeal />
        <Footer />
      </div>
    </div>
  );
}
