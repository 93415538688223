import styles from "../style";
import Button from "./Button";

const CTA = () => (
  <section className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
    <div className="flex-1 flex flex-col">
      <h2 className={styles.heading2}>Let’s try our service now!</h2>
      <p className={`${styles.paragraph} max-w-[591px] mt-5`}>
      Experience excellence in action! Now is the perfect time to explore our service.Don't wait, seize this opportunity to work with us,a company that sets industry standards.Contact us today and discover how we can transform your aspirations into achievements.
      </p>
    </div>

    <a href="#contactUs" className={`py-4 px-6  text-center font-poppins font-medium text-[18px] text-[#00040F] hover:opacity-90 bg-blue-gradient rounded-[10px] outline-none ${styles} w-full sm:w-1/4 mt-2` }>
    Get Started
    </a>
  </section>
);

export default CTA;