// import React from 'react'
import prince from '../assets/prince.jpeg'
import chehak from '../assets/chehak.jpeg'
import yugank from '../assets/yugank.jpeg'
import React, { useState } from 'react';

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  };

  const slides = [
    { src: prince, name: 'Prince Raj',desc:"I can't thank SoftwareIndia enough for their top-notch web development services. They transformed my vision into a stunning, user-friendly website that exceeded my expectations. Their team's technical expertise, responsiveness, and commitment to delivering quality make them my go-to choice for web development. I'm thrilled with the results!"  },
    { src: chehak, name: 'Chehak Batra' ,desc:"I was blown away by the exceptional video editing services provided by SoftwareIndia. Their attention to detail and creativity turned my project into a masterpiece. The team at SoftwareIndia is professional, reliable, and dedicated to delivering outstanding results. I highly recommend them for all your video editing needs." },
    { src: yugank, name: 'Yugank Bansal',desc:"I can't express how pleased I am with the graphics design services provided by SoftwareIndia. Their team's creativity and attention to detail are simply outstanding. They took my concepts and turned them into visually stunning artwork that perfectly represents my brand. Working with them was not only a smooth and enjoyable experience, but the final results exceeded my expectations. I highly recommend SoftwareIndia for all your graphic design needs." },
  ];

  return (
    <div id="carouselExampleAutoplaying" className="carousel  carousel-fade" data-bs-ride="carousel" data-interval="500">
    <h1 className="test text-white text-center text-5xl font-bold">Testimonials</h1>
      <div className="carousel-inner">
        {slides.map((slide, index) => (
          <div key={index} className={`carousel-item${activeIndex === index ? ' active' : ''} transition-opacity duration-500 `}>
          <div className="flex flex-col items-center p-8 ">
            <img src={slide.src} className="d-block h-[150px] w-[150px] rounded-[150px]" alt="..." />
            <h1 className="font-bold text-white py-2 text-2xl">{slide.name}</h1>
            <h1 className="text-dimWhite md:px-40 px-4">{slide.desc}</h1>
            </div>
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        onClick={handlePrev}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        onClick={handleNext}
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
