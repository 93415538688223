import React, { useState } from 'react'
import styles  ,{ layout }   from '../style'
import {swo} from "../constants"
const FeatureCard = ({ icon, title, content, index }) =>{
  const [toggle,setToggle]=useState(false)
  const [more,setMore]=useState(content.slice(0,200))
  function handleClick() {
    toggle?setMore(content.slice(0,200)):setMore(content)
    setToggle(!toggle)
  }
  return(
    <div className={`flex justify-center items-center sm:w-[30%] flex-col p-6 rounded-[20px] ${index !== swo.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
      <div className="flex w-full justify-center items-center mb-6">
        <img src={icon} alt="star" className=" w-[60%] sm:w-[70%] h-fit" />
      </div>
      <div className="flex-1 flex flex-col mt-3">
        <h4 className="font-poppins font-semibold text-white text-[18px] leading-[23.4px] mb-1 text-center">
          {title}
        </h4>
        <p className="font-poppins font-normal text-dimWhite text-[14px] leading-[16px]">
          {more}
          <br />
          <button className='text-white' onClick={()=>handleClick()}>{toggle?"Read Less":"Read More"}</button>
        </p>
      </div>
    </div>
  )};

const Swo = () => {
  return (
    <section id="serv" className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-col flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
        
    <div>
    <div className="text-white pt-5 text-[2.5rem] sm:text-5xl font-bold text-center mb-8">Services We Offer.</div>
    <div className="flex justify-center flex-wrap flex-col sm:flex-row md:mt-0 mt-10 relative">
            {swo.map((info, index) => (
                <FeatureCard key={info.id} {...info} index={index} />
            ))}
            </div>
    </div>
    </section>
  )
}

export default Swo
