import { contact } from "../assets";
import styles, { layout } from "../style";
import { useForm, ValidationError } from '@formspree/react';
import  Form from "./Form";

const CardDeal = () => (
  <section className={layout.section} id="contactUs">
    <div className={layout.sectionImg}>
      <img src={contact} alt="billing" className="md:w-[90%] w-full" />
    </div>
    <div className={layout.sectionInfo}>
    <Form id="contact"/>
    </div>
  </section>
);

export default CardDeal;