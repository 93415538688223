import React from 'react'
import styles, { layout } from "../style";
import { ser } from '../assets';
import { Link } from 'react-router-dom';

const sermain = () => {
  return (
    <div className='justify-center'>
      <section className={`${styles.flexCenter} flex-row-reverse flex-wrap sm:mb-20 mb-6 `}>
        <div className="content-center">
          <h4 className='text-white '>Services at <span className="font-bold text-[#03F6FF]">SoftwareIndia</span></h4>
          <h2 className={styles.heading2}>
            Why Hire <span className="font-bold text-[#03F6FF]">Us</span>?
          </h2>
          <div class="h-52 w-52 absolute rounded-full bg-blue-500 blur-[150px]"></div>
          <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
            Hiring us ensures expertise, reliability, and efficiency in web development, design, and maintenance.We bring technical proficiency, save time, and deliver a polished online presence, crucial for business success.
          </p>
          <Link to="/Contact"> 
          <button type="button" className={`py-4 px-6 font-poppins font-medium text-[18px] text-[#00040F] bg-blue-gradient rounded-[10px] outline-none mt-6 w-full animate-floating2 sm:w-1/2`}>
            Contact Us Now.
          </button>
          </Link>
        </div>

        <div className={`${layout.sectionImg} flex-col content-center`}>
          <img src={ser} className="sm:mr-8  w-[430px] " alt="" />
          
        </div>





      </section>
    </div>
  )
}

export default sermain
