import React from "react";
import styles from "../style";
import Navbar from "./Navbar";

export default function Navba() {
  return (
    <div>
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
        </div>
      </div>
    </div>
  );
}
