import React from 'react'
import { Domains } from '../constants'

export default function UseCases() {
  return (
    <div className="use-section">
      <div className="use-container">
        <div className="use-text-head"><a href="#0" className="use-text-effect">Domains</a></div>
        <div className="use-slider">
          <div className="slide">
            {Domains?.map((v,i) => 
            <div key={i} className="use-text">
              {v.Domain}
              <span>
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.97488 0.139755L8.38849 5.41724L13.7246 3.92195L14.1937 7.52824L9.32671 7.88007L12.5225 12.1314L9.26807 13.8612L7.0398 9.37536L5.0754 13.8319L1.70367 12.1314L4.87017 7.88007L0.0324707 7.49892L0.589539 3.92195L5.80839 5.41724L5.222 0.139755H8.97488Z"
                    fill="#6D9CFE"
                  ></path>
                </svg>
              </span>
            </div>
          )};
          </div>
        </div>
      </div>
    </div>
  )
}
