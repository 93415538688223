import React from 'react'
import styles, { layout } from "../style";
import { careerimg } from '../assets';

const Business = () =>  (
  <section id="features" className={layout.section}>
    <div className={layout.sectionInfo}>
      <h4 className='text-white '>Careers at <span className="font-bold text-[#03F6FF]">SoftwareIndia</span></h4>
      <h2 className={styles.heading2}>
        Work with <span className="font-bold text-[#03F6FF]">Us</span>.
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5 ` }>
      Explore remote-friendly, flexible opportunities and join our mission to make work life simpler, more pleasant and more productive.
      </p>

      <a target='_blank' rel="noreferrer" href="https://forms.gle/NyEkzrQwn7V9VC1Q9" className={`text-center mt-10 py-3 px-6 font-poppins font-medium text-[18px] text-[#00040F] hover: opacity-90 bg-blue-gradient rounded-[10px] outline-none ${styles} md:w-full w-5/6 sm:w-1/3 ` }>
    Get Started
  </a>
    </div>

    <div className={`${layout.sectionImg} flex-col`}>
      <img src={careerimg} alt="" className='p-4' />
      <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
      <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
      <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
    </div>
  </section>
);

export default Business;
