import React from "react";
import styles from "../style";
import { Workwithus } from "../components";
import { Careerfeatures ,Footer,CardCareer,} from "../components";

export default function Career() {
  return (
    <div>
      <div className={`bg-[#00040F] ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Workwithus/>
          <Careerfeatures/>
          <h1 id="cards" className="text-center text-white text-6xl font-bold mt-[7rem]">Careers.</h1>
          <h1 className="text-center text-dimWhite text-xl font-bold">Grow with Us.</h1>
          <CardCareer/>
          <Footer />
        </div>
      </div>
    </div>
  );
}
