import {
  people01,
  people02,
  people03,
  facebook,
  instagram,
  linkedin,
  twitter,
  freelancer,
  upwork,
  fiverr,
  designslogo,
  send,
  shield,
  star,
  Connect,
  web,
  uiux,
  video,
  graphics,
  hr,
  marketing,
  branding,
  cost,
  deadline,
  domain,
  security,
  updates,
  videoediting,
  accolade,
  networking,
  learning,
  flexible,
  corporate,
} from "../assets";

export const navLinks = [
  {
    id: "/",
    title: "Home",
  },
  {
    id: "services",
    title: "Services",
  },
  {
    id: "career",
    title: "Careers",
  },
  {
    id: "contact",
    title: "Contact",
  },
];
export const whyc = [
  {
    id: "1",
    icon: deadline,
    title: "Assured Deadline Completion",
    content:
      "Guaranteed on-time project delivery, a testament to our commitment in providing reliable services with punctuality and quality.",
  },
  {
    id: "2",
    icon: security,
    title: "100% Secured",
    content:
      "Ensuring 100% secured innovation through robust NDAs, safeguarding your intellectual property and fostering trust in our partnership.",
  },
  {
    id: "3",
    icon: cost,
    title: "Cost Effective Pricing",
    content:
      "Our company prioritizes affordability while maintaining uncompromised quality, ensuring exceptional value for our clients.",
  },
];
export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Assured Deadline Completion",
    content:
      "Guaranteed on-time project delivery, a testament to our commitment in providing reliable services with punctuality and quality.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "100% Secured",
    content:
      "Ensuring 100% secured innovation through robust NDAs, safeguarding your intellectual property and fostering trust in our partnership.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Cost Effective Pricing",
    content:
      "Our company prioritizes affordability while maintaining uncompromised quality, ensuring exceptional value for our clients.",
  },
];
export const swo = [
  {
    id: "feature-1",
    icon: web,
    title: "Web Development",
    content:
      "Unlock your digital potential with our web development expertise. We craft tailored, responsive, and user-focused websites, ensuring seamless performance on all devices. Our team delivers innovative web solutions and SEO-optimized designs.all while maintaining a commitment to scalability and efficiency. ",
  },
  {
    id: "feature-2",
    icon: branding,
    title: "Branding",
    content:
      "Elevate your brand's identity with our branding expertise. We specialize in creating compelling brand stories, visual identities, and memorable experiences. Our team's commitment to consistency, unique positioning, and impactful brand strategies sets you apart in the market. Discover the power of a well-defined brand with us.",
  },
  {
    id: "feature-3",
    icon: videoediting,
    title: "Video Editing",
    content:
      "Transform your content with our video editing prowess. We craft captivating and professional video productions. Our team excels in seamless edits, striking visuals, and enhancing your message for maximum impact. Elevate your video storytelling with us.",
  },
  {
    id: "feature-4",
    icon: domain,
    title: "Domain Management",
    content:
      "Streamline your online presence with our domain management services. We handle domain registration, DNS configuration, and ensure your web address is always secure and operational. Simplify your digital journey with our expertise.",
  },
  {
    id: "feature-5",
    icon: security,
    title: "Security",
    content:
      "Protect your digital assets with our security solutions. We offer comprehensive cybersecurity measures, robust firewalls, and proactive threat detection to safeguard your online presence. Trust us to keep your data and reputation secure..",
  },
  {
    id: "feature-3",
    icon: updates,
    title: "Updates and Management",
    content:
      "Ensure your online presence stays optimized and secure with our updates and security services. We deliver timely software updates and maintain strong security measures to protect your digital assets. Count on us for a seamless, safe, and reliable web experience.",
  },
];

export const careerinfo = [
  {
    id: "info-1",
    icon: flexible,
    title: "Flexible Working Hours",
    content:
      "Enjoy freedom in your workday with flexible hours, empowering you to balance life and career on your terms,work whenever you want and wherever you want.",
  },
  {
    id: "info-2",
    icon: corporate,
    title: "Corporate Working Environment",
    content:
      "Discover a thriving corporate ecosystem where professionalism meets innovation. Join us to shape your career, fueled by our sense of unity and growth opportunities.",
  },
  {
    id: "info-3",
    icon: hr,
    title: "Interview Based Selection",
    content:
      "Interview-based selection unveils hidden gems, uncovering the potential for remarkable achievements. It's the path to recognition and fulfillment, where talent meets opportunity.",
  },
  {
    id: "info-4",
    icon: accolade,
    title: "Skill Building",
    content:
      "Empower personal and professional growth through structured skill-building. Access a vast array of resources, courses, and hands-on experience to develop expertise and knowledge across a wide spectrum of subjects, fostering lifelong learning and career advancement.",
  },
  {
    id: "info-5",
    icon: learning,
    title: "Learning",
    content:
      "Learning empowers you by enhancing skills, fostering adaptability, and unlocking career growth, making you a valuable asset and promoting personal fulfillment.Here you will be guided by trusted seniors of your chosen fields so you do not commit the same mistakes as you embark on your journey.",
  },
  {
    id: "info-6",
    icon: networking,
    title: "Networking",
    content:
      " Build connections with like-minded, passionate individuals in a smaller, close-knit environment.This is your chance to to gain exposure and Cultivate the art of synergy, where individual strengths harmonize to create an unstoppable force, fostering an indomitable team spirit.",
  },
];

export const cardcareer = [
  {
    id: "card-1",
    icon: web,
    title: "Web Development",
    content:
      " Here we connect aspiring web developers with internships, kickstarting careers in the dynamic web development field lead by trusted seniors.Your blend of technical prowess and digital charisma will set you apart from your peers.",
      butt: "Apply Now!",
  },
  {
    id: "card-2",
    icon: uiux,
    title: "UI/UX designer",
    content:
      "This is your chance to gain market exposure and learn indemand practices and tools entailing the best experience for you through real projects.refine your design skills while infusing your unique personality into every pixel. Your creative touch will reshape the digital landscape.",
    butt: "Apply Now!",
  },
  {
    id: "card-3",
    icon: video,
    title: "Video Editing ",
    content:
      "Gain hands-on experience, collaborate with experts, and launch your creative career. Join us in shaping the future of media production. Your journey begins here.",
      butt: "Apply Now!",
  },
  {
    id: "card-4",
    icon: graphics,
    title: "Graphics Designer",
    content:
      "Ignite your creativity as a graphics intern. Dive into live projects, collaborate with experts, and kickstart your graphic design career. Join us in shaping the visual future. ",
      butt: "Apply Now!",
  },
  {
    id: "card-5",
    icon: hr,
    title: "HR",
    content:
      " Gain practical experience, collaborate with professionals, and launch your HR career.Nurture not only your HR expertise but also your ability to connect with people. In HR, strong communication skills and a great personality are the keys to unlocking success.",
    butt: "Available Later",
  },
  {
    id: "card-6",
    icon: marketing,
    title: "Marketing",
    content:
      "Elevate your marketing prowess and cultivate a captivating personality that speaks volumes.In the realm of marketing, ignite your communication skills and let your vibrant personality set the world on fire!",
    butt: "Available Later",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
    name: "Herman Jensen",
    title: "Founder & Leader",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "Money makes your life easier. If you're lucky to have it, you're lucky.",
    name: "Steve Mark",
    title: "Founder & Leader",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "It is usually people in the money business, finance, and international trade that are really rich.",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: people03,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "3800+",
  },
  {
    id: "stats-2",
    title: "Trusted by Company",
    value: "230+",
  },
  {
    id: "stats-3",
    title: "Transaction",
    value: "$230M+",
  },
];

export const footerLinks = [
  {
    title: "Page Links",
    links: [
      {
        name: "Home",
        link: "/",
      },
      {
        name: "Services",
        link: "/services",
      },
      {
        name: "Careers",
        link: "/career",
      },
      {
        name: "Contact",
        link: "/contact",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Instagram",
        link: "https://www.instagram.com/soft.wareindia/",
      },
      {
        name: "Facebook",
        link: "https://www.facebook.com/",
      },
      {
        name: "LinkedIn",
        link: "https://www.linkedin.com/company/softwareindia/",
      },
      {
        name: "Twitter",
        link: "https://www.twitter.com/",
      },
    ],
  },
  {
    title: "Platforms",
    links: [
      {
        name: "Freelancer",
        link: "https://www.freelancer.in/",
      },
      {
        name: "UpWork",
        link: "https://www.upwork.com/",
      },
      {
        name: "Fiverr.",
        link: "https://www.fiverr.com/",
      },
      {
        name: "99designs",
        link: "https://99designs.com/",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/soft.wareindia/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/company/softwareindia/",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: freelancer,
  },
  {
    id: "client-2",
    logo: upwork,
  },
  {
    id: "client-3",
    logo: fiverr,
  },
  {
    id: "client-4",
    logo: designslogo,
  },
];

export const Domains = [
  {
    Domain: "Web-Development",
  },
  {
    Domain: "Video Editing",
  },
  {
    Domain: "Search Engine Optimisation",
  },
  {
    Domain: "UI/UX Designing",
  },
  {
    Domain: "Graphic Designing",
  },
  {
    Domain: "Content Writing",
  },
  {
    Domain: "Web-Development",
  },
  {
    Domain: "Video Editing",
  },
  {
    Domain: "Search Engine Optimisation",
  },
  {
    Domain: "UI/UX Designing",
  },
  {
    Domain: "Graphic Designing",
  },
  {
    Domain: "Content Writing",
  },
  {
    Domain: "Web-Development",
  },
  {
    Domain: "Video Editing",
  },
  {
    Domain: "Search Engine Optimisation",
  },
  {
    Domain: "UI/UX Designing",
  },
  {
    Domain: "Graphic Designing",
  },
  {
    Domain: "Content Writing",
  },
];
