import React from 'react'
import styles from "../style";
import { Footer,Sermain,WhyC,Swo} from '../components';
export default function Services(){
  return (
    <div>
       <div className={`bg-[#00040F] ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Sermain/>
         < WhyC/>  
        < Swo/>
          <Footer/>

        </div>
      </div>
    </div>
  )
}


