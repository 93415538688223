import styles from "../style";
import {  HeroImg } from "../assets";
import GetStarted from "./GetStarted";
import { MdDone } from 'react-icons/md';
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY}`}>
      <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
        <div className="flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2 animate-pulse">
       
          <MdDone className="w-[1.5rem] h-[1.5rem] text-[#5DE1E6]"/>
          <p className={`${styles.paragraph} ml-2 text-sm md:text-xl text-[#5DE1E6]`}>
            <span className="text-white">Service</span> Simplified ,{" "}
            <span className="text-white">Results</span> Amplified
          </p>
        </div>

        <div className="flex flex-row justify-between items-center w-full">
          <h1 className="flex-1 font-poppins font-semibold ss:text-[72px] text-[32px] text-white ss:leading-[100.8px] leading-[50px]">
          Pioneering <br className="sm:block hidden" />
            <span className="text-gradient text-4xl ss:text-7xl">Perseverance,</span>
          </h1>
          <div className="ss:flex hidden md:mr-4 mr-0">
          <Link to="/Contact">
            <GetStarted />
            </Link>
          </div>
        </div>

        <h1 className="font-poppins font-semibold ss:text-[68px] text-[32px] text-white ss:leading-[100.8px] leading-[50px] w-full">
        Achieving Excellence
        </h1>
        
        <img src={HeroImg} alt="billing" className="p-4 z-[5] relative  md:hidden block animate-floating " />
    
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          Our team of experts uses a methodology to identify and provide necessary & exceptional services to our fellow visionaries.
        </p>
      </div>

      <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
        <img src={HeroImg} alt="billing" className=" p-3 z-[5] relative bottom-16 max-w-[450px] animate-floating md:block hidden " />
        
        <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
        <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      
      </div>

      <div className={`ss:hidden ${styles.flexCenter}`}>
        <GetStarted />
      </div>
    </section>
  );
};

export default Hero;