import { cardcareer } from "../constants";
import styles, { layout } from "../style";

const FeatureCard = ({ icon, title, content, index, butt }) => (
  <div className={`flex flex-col md:py-8 py-8 md:w-[30%] sm:w-[45%] rounded-[20px] items-center gap-2 career-card`}>
    {/* <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBluee`}> */}
      <img src={icon} alt="star" className="w-[70%] h-[70%] object-contain p-2" />
    {/* </div> */}
    <div className="flex flex-col">
      <h4 className="font-poppins font-semibold text-white text-[18px] leading-[23.4px] mb-1 text-center">
        <span className="text-[#00F6FF] ">{title}</span> Intern
      </h4>
      <p className="font-poppins font-normal text-dimWhite text-[15px] leading-[20px] p-3">
        {content}
      </p>
    </div>
    <a href="https://forms.gle/NyEkzrQwn7V9VC1Q9" target="_blank" rel="noopener noreferrer">
    <button type="button" className={`btn btn-outline-light`}>
      {butt}
    </button>
    </a>
  </div>
);

const CardCareer = () =>  (
  <section id="cardcareer" className={layout.section}>
    <div className="flex flex-row flex-wrap gap-5 justify-center">
      {cardcareer.map((feature, index) => (
        <FeatureCard key={feature.id} {...feature} index={index} />
      ))}
    </div>
  </section>
);

export default CardCareer;
