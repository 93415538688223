import React from 'react'
import styles  ,{ layout }   from '../style'
import {whyc} from "../constants"

const FeatureCard = ({icon,title, content, index }) => (
    <div className={`flex sm:w-[33%] flex-col p-8 rounded-[20px] text-center  ${index !== whyc.length - 1 ? "mb-6" : "mb-0"}`}>
      <div className="flex-1 flex flex-col">
        <div className="flex w-full justify-center items-center mb-6">
            <img src={`${icon}`} alt="" className='w-[60%] sm:w-[60%]' />
        </div>
        <h4 className="font-poppins font-semibold text-white text-lg leading-[20px] mb-2 border-b-[2px] p-2 border-dimWhite/30 ">
          {title}
        </h4>
        <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px] ">
          {content}
        </p>
      </div>
    </div>
  );
  
const WhyC = () => {
  return (
     
    <section id="serv" className={`sm:flex-col flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
        
        <div>
        <div className="text-white text-4xl sm:text-5xl font-bold text-center mb-8 pt-5">Why Choosing Us is a <span className="text-[#03F6FF] "> Smart Move</span>?</div>
        <div className="flex justify-center flex-wrap flex-col sm:flex-row md:mt-0 mt-10 relative">
                {whyc.map((info, index) => (
                    <FeatureCard key={info.id} {...info} index={index} />
                ))}
                </div>
        </div>
    </section>
  )
}

export default WhyC
