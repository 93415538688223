import React from 'react'
import  Home  from "./Pages/Home"
import  Career  from "./Pages/Career"
import Services from "./Pages/Services"
import { BrowserRouter ,Routes,Route} from "react-router-dom";
import Navba from './components/Navba';
import { CardDeal } from './components';
import Contact from './Pages/Contact';

const App = () => (
  <div className="bg-[#00040F] w-full overflow-hidden">
    <BrowserRouter future={{ v7_startTransition: true }}>
      <Navba />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="career" element={<Career />} />
      <Route path="services" element={<Services/>}/>
      <Route path="contact" element={<Contact />}/>
      </Routes>
    </BrowserRouter>
  </div>
);

export default App

