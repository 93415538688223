import React from "react";
import { Business, Clients, CTA, Footer, Hero, UseCases,Billing,CardDeal ,Testimonials,Form ,Carousel} from "../components";
import styles from "../style";



export default function Home() {
  return (
    <div>
      <div className={`bg-[#00040F] ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
        
          <Hero />
        </div>
      </div>
      <div className={`bg-[#00040F] ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          {/* <Stats /> */}
          <Business />
          {/* <Billing /> */}
          <Carousel />
          {/* <Testimonials /> */}

          <CTA />
          <Clients />
          <UseCases />
          <CardDeal />
         
          <Footer />
        </div>
      </div>
    </div>
  );
}
