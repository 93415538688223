import { careerinfo } from "../constants";
import styles, { layout } from "../style";
import Typewriter from "typewriter-effect";

const FeatureCard = ({ icon, title, content, index }) => (
  <div className={`flex justify-start items-center sm:w-[33%] flex-col p-3 rounded-[5px] ${index !== careerinfo.length - 1 ? "mb-6" : "mb-0"} `}>
    <div className={`flex items-center`}>
      <img src={icon} alt="star" className="md:h-[200px] h-[210px]" />
    </div>
    <div className="flex-1 flex flex-col mt-3">
      <h4 className="font-poppins font-semibold text-white text-[18px] leading-[20px] mb-1 ">
        {title}
      </h4>
      <p className="font-poppins font-normal text-dimWhite text-[12px] sm:text-[14px] leading-[24px]">
        {content}
      </p>
    </div>
  </div>
);

const Business = () =>  (
  <section id="careerinfo" className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-col flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
    <div className={`${layout.sectionInfo} items-center`}>
      <h2 className="font-poppins font-semibold  xs:text-[2.8rem] text-[30px] text-white xs:leading-[60px] w-full">
      <span className="font-bold text-[#03F6FF]">SoftwareIndia</span> is where the future works.
      </h2>     
        <p className={`text-white max-w-[900px]  mt-5 text-center text-4xl sm:text-5xl font-bold pb-5` }>    
         
              <Typewriter
        options={{
          strings: ['Why Join Us?', 'This Is Why!'],
          autoStart: true,
          loop: true,
        }}
      />
        </p>        
    </div>

    <div className={`${layout.sectionImg} flex-wrap sm:flex-row flex-col`}>
      {careerinfo.map((info, index) => (
        <FeatureCard key={info.id} {...info} index={index} />
      ))}
    </div>
  </section>
);

export default Business;